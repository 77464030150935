import React, { useEffect } from "react";
import loadable from "@loadable/component";
import {LanguageContext, useTranslateField} from "globant";
import "./layout.scss";


// Files
const Navbar = loadable(() => import('../navbar/navbar'));
const Hero = loadable(() => import('../hero/hero'));
const About = loadable(() => import('../about/about'));
const Why = loadable(() => import('../why/why'));
const Globar = loadable(() => import('../globar/globar'));
const Events = loadable(() => import('../events/events'));
const Speakers = loadable(() => import('../speakers/speakers'));
const Agenda = loadable(() => import('../agenda/agenda'));
const Playlist = loadable(() => import('../playlists/playlistSlider'));
const Merchandising = loadable(() => import('../merchandising/merchandising')); 
const Register = loadable(() => import('../register/register'));
const Footer = loadable(() => import('../footer/footer'));

export default function Layout({ location }) {

	const [data, setListData] = React.useState([]);
	const [active, setActive] = React.useState(false);

	const t = useTranslateField;

	useEffect(() => {

		let time_stamp = Math.floor(Date.now() / 1000);

		const url = process.env.GATSBY_API_URL + "?timestamp=" + time_stamp;

		const fetchData = async () => {
			try {
				const response = await fetch(url);
				const json = await response.json();
				setListData(json);
				setActive(true);
			} catch (error) {
				console.log("error", error);
			}
		};
		fetchData();
	}, []);

if (data) { } 
	const showAgenda = !!t({ field: data.field_show_agenda });
	const showRegisterForm = !!t({ field: data.field_show_register_form });
	const showSpeakersSection =  !!t({ field: data.field_show_speakers });

	return (
		<LanguageContext.Consumer>
			{language => (
			<>
				<div className={active ? 'hide-loader' : 'show-loader'}></div>
				<div>{( data.length !== 0 ? <Navbar data={data} currentPage="/" location={ location }/>: null)}</div>
				<div id="home" className="hero-wrapper">{( data.length !== 0 ? <Hero data={data} location={ location }/>: null)}</div>
				<div id="about" className="about-wrapper">{( data.length !== 0 ? <About data={data} location={ location }/>: null)}</div>
				<div id="why" className="why-wrapper">{( data.length !== 0 ? <Why data={data} location={ location }/>: null)}</div>
				<div id="globar" className="globar-wrapper">{( data.length !== 0 ? <Globar data={data} location={ location }/>: null)}</div>
				<div id="events" className="events-wrapper">{( data.length !== 0 ? <Events data={data} location={ location }/>: null)}</div>
				{(
					showSpeakersSection ?
						<div id="speakers" className="speakers-wrapper">{( data.length !== 0 ? <Speakers speakerData={data} location={ location }/>: null)}</div> :
						null
				)}
				{(
					showAgenda ? 
						<div id="agenda" className="agenda-wrapper">{( data.length !== 0 ? <Agenda agenda={data} location={ location }/>: null)}</div> : 
						null
				)}
				<div id="play" className="play-wrapper">{( data.length !== 0 ? <Playlist playData={data} location={ location }/>: null)}</div>
				<div id="merchan" className="merchandising-wrapper">{( data.length !== 0 ? <Merchandising merchandising={data} location={ location }/>: null)}</div>
				{ showRegisterForm ?
					<div id="joinus" className="joinus-wrapper">{( data.length !== 0 ?
						<Register location={ location }
							thankyouMessageTitle={ data.field_thank_you_message_title ? data.field_thank_you_message_title : null  }
							thankyouMessage={ data.field_thank_you_message ? data.field_thank_you_message : null  } />
						: null)}</div>
					: null
				}
				<div id="my-form-12">{( data.length !== 0 ? <Footer data={data} location={ location }/>: null)}</div>
			</>
			)}
		</LanguageContext.Consumer>
	)
}